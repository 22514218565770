import { useFormContext, Controller } from 'react-hook-form';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';

type IProps = {
  name: string;
  size?: 'small' | 'medium';
  testId: string;
};

type Props = IProps & MuiTelInputProps;

const hungaryCountryCode = 'HU';
const hungaryPhoneCode = '+36';

export default function RHFTextField({ name, label, size, testId, langOfCountryName }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => (
        <MuiTelInput
          {...fieldProps}
          forceCallingCode
          size={size}
          langOfCountryName={langOfCountryName}
          fullWidth
          value={fieldProps.value}
          inputRef={ref}
          error={!!error}
          helperText={error?.message}
          variant="outlined"
          placeholder={
            !fieldProps.value || fieldProps.value === hungaryPhoneCode ? '30 123 4567' : ''
          }
          defaultCountry={hungaryCountryCode}
          label={label}
          slotProps={{
            htmlInput: {
              'data-testid': testId,
            },
          }}
        />
      )}
    />
  );
}
